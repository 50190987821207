import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import FeatureBlock from 'common/components/FeatureBlock';
import TeamSectionWrapper from './teamSection.style';

const TeamSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  memberName,
  designation,
  contentStyle,
  descr
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        teamMember {
          name
          designation
          id
          thumbnail_url {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          social_links {
            id
            icon
            url
          }
        }
      }
    }
  `);

  return (
    <TeamSectionWrapper id="teamSection">
      <Container>
        <Box {...sectionHeader}>
          <Text content="LEADERSHIP" {...sectionSubTitle} />
          <Heading
            content="40 Years of Innovation"
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row}>
          {Data.agencyJson.teamMember.map((member, index) => (
            <Box className="col" {...col} key={`team_key-${index}`}>
              <FeatureBlock
                id={`member-${member.id}`}
                className="team__member"
                icon={
                  <GatsbyImage
                    src={
                      (member.thumbnail_url !== null) | undefined
                        ? member.thumbnail_url.childImageSharp.gatsbyImageData
                        : {}
                    }
                    alt={`Team member photo ${member.id}`}
                    className="member__photo"
                  />
                }
                contentStyle={contentStyle}
                title={<Heading content={member.name} {...memberName} />}
                description={
                  <Fragment>
                    <Text content={member.designation} {...designation} />

                    {/* <SocialLinks>
                      {member.social_links.map((social, index) => (
                        <a
                          href={social.url}
                          key={`profile_id-${index}`}
                          aria-label={social.icon}
                        >
                          <i className={social.icon}></i>
                        </a>
                      ))}
                    </SocialLinks> */}
                    <Text content={`Saddle Mountain Group is led by Maury Plumlee, founder and principal partner. Maury started his career in sales with Portland-based Viewpoint in 1980, and his sales management responsibilities grew as the company expanded into a global leader in construction ERP solutions. During this time-period, Viewpoint grew from less than 20 employees to over 800 today.`} {...descr} />
                    <Text content={`Maury’s skills and expertise were honed by the competitive world of front-line ERP sales; this gave him the foundation to help build and lead the Viewpoint Sales Organization through substantial growth and success. In 2015, after a 30+ year career in all aspects of sales, Maury moved to VP of Global Marketing, where he led branding, messaging & lead generation activities across Viewpoint’s global organization. Viewpoint was acquired by Trimble Corporation in 2018.`} {...descr} />
                    <Text {...descr}>Want to learn more? Reach out with any questions <a href="mailto:mv1plumlee@gmail.com">here</a>.</Text>
                  </Fragment>
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </TeamSectionWrapper>
  );
};



// TeamSection style props
TeamSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
};

// TeamSection default style
TeamSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Team member col default style
  col: {
    width: [1],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // Team member content default style
  contentStyle: {
    textAlign: 'center',
    mt: '25px',
  },
  // Team member memberName default style
  memberName: {
    fontSize: ['18px', '18px', '16px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
    textAlign: 'left',
  },
  // Team member description default style
  designation: {
    fontSize: ['15px', '16px', '14px', '17px'],
    lineHeight: '1',
    color: 'rgba(15, 33, 55, 0.6)',
    mb: 0,
    textAlign: 'left',
  },
  descr: {
    mt: '20px',
    textAlign: 'left',
    fontSize: ['15px', '16px', '14px', '17px'],
    lineHeight: '1.4',
    color: 'rgba(15, 33, 55, 0.6)',
    mb: 0,
    textAlign: 'left',
  },
};

export default TeamSection;
