import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/theme/agency';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, AgencyWrapper } from 'containers/Agency/agency.style';
import Navbar from 'containers/Agency/Navbar';
import BannerSection from 'containers/Agency/BannerSection';
import FeatureSection from 'containers/Agency/FeatureSection';
import BlogSection from 'containers/Agency/BlogSection';
import TeamSection from 'containers/Agency/TeamSection';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import Seo from 'components/seo';

const Agency = () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <Seo title="Home" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <FeatureSection />
          <BlogSection />
          {/* <AboutUsSection /> */}
          {/* <WorkHistory /> */}
          
          {/* <QualitySection /> */}
          {/* <VideoSection />*/}
          {/* <TestimonialSection />  */}
          <TeamSection />
          {/* <FaqSection /> */}
          {/* <NewsletterSection /> */}
          {/* <Footer /> */}
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Agency;
