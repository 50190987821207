import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Particles from '../../Agency/Particle';
import BannerWrapper from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <AnchorLink
        href={"#featureSection"}
      >
        <Button title="LEARN MORE" {...btnStyle} />
      </AnchorLink>
      
      {/* <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  return ( 
    <BannerWrapper>
      <div id={`bannerSection`} style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        //background: rgba(0,0,0,.4)
      }}></div>


      {/* <svg viewBox="0 0 1600 900" style={{position: 'absolute', top: 0, left: 0, height: '100%'}}> 
        <path fill="#ffffff" clipPath="url('#myClip')" opacity="1" d="M0,900L1600,900,1600,870,L1600,870,1100,870,C1070,870,1040,840,1020,810,L1020,810,500,0,C1600, 0,1600, 0,1600, 0C1600, 0,1600, 0,1600, 0C1600, 0,1600, 0,1600, 0C1600, 0,1600, 0,1600, 0L1600,0C1333,0,1066,0,799,0,C532,0,265,0,-2,0,C0, 0,0, 0,0, 0C0, 0,0, 0,0, 0C0, 0,0, 0,0, 0C0, 0,0, 0,0, 0L1401,0L0,0Z" /> 
       <path fill="#ffffff" opacity="1" d="M1600,870,L1600,870,1400,870,C1370,870,1340,840,1320,810,L1320,810,800,0,L800,0,0,0L0,0,0,900,L0,900,1600,900,L1600,900,1600,870" /> 
        <path stroke="none" fill="#20be86" d="M176.875644347018 117.263485025341a14 14 0 0 1 124.248711305964 0274.751288694036 229.47302994932a14 14 0 0 1 -112.124355652982 31l-249.50257738807 0a14 14 0 0 1 -112.124355652982 -121" style={{transform: 'translate(100%, 100%)'}}></path> 
       </svg>  */}
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content="Saddle Mountain" {...discountAmount} />
              <Text content="Group" {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              title={
                <Heading
                  content="Strategic Advisors to Emerging Tech Organizations"
                  {...title}
                  style={{marginTop: 40}}
                />
              }
              description={
                <Text
                  content="We draw upon 40 years of industry-leading experience to help business achieve measurable and sustainable growth."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '55px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
